<template>
  <s-dialog
    :visible="visible"
    :show-close="true"
    :append-to-body="true"
    class="rdf-taxes-dialog"
    @update:visible="updateVisible"
  >
    <div class="rdf-taxes-dialog__content">
      <p class="rdf-descriptions-text">
        {{ quantityText }}
      </p>
      <div class="order-no-list">
        <div class="order-no-list__content">
          <p
            v-for="(item, index) in orderNoList"
            :key="index"
            class="order-no-item"
          >
            {{ item }}
          </p>
        </div>
      </div>
      <p class="rdf-descriptions-text">
        {{ refundText }}
      </p>
    </div>
    <template slot="footer">
      <s-button
        :type="['primary', 'H72PX']"
        :block="true"
        width="100%"
        @click="updateVisible(false)"
      >
        {{ language.SHEIN_KEY_PWA_15146 }}
      </s-button>
    </template>
  </s-dialog>
</template>

<script setup>
import { computed } from 'vue'
import { template } from '@shein/common-function'
const emit = defineEmits(['update:visible'])

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  language: {
    type: Object,
    default: () => {}
  },
  rdfOrderNoList: {
    type: Array,
    default: () => []
  },
  rdfRefund: {
    type: Object,
    default: () => {}
  }
})

const quantityText = computed(() => {
  const total = props.rdfOrderNoList?.length || 0
  return template(total, props.language.SHEIN_KEY_PWA_31519)
})

const orderNoList = computed(() => {
  if (Array.isArray(props.rdfOrderNoList)) {
    return props.rdfOrderNoList.map(orderNo => {
      return template(orderNo, props.language.SHEIN_KEY_PWA_31520)
    })
  }
  return []
})

const refundText = computed(() => {
  const text = props.rdfRefund?.amountWithSymbol || ''
  return template(text, props.language.SHEIN_KEY_PWA_31521)
})

const updateVisible = event => {
  emit('update:visible', event)
}
</script>

<style lang="less">
.rdf-taxes-dialog {
  .rdf-descriptions-text {
    font-size: 12px;
    color: @sui_color_gray_dark3;
    word-break: break-word;
  }

  .order-no-list {
    padding: 24/75rem;
    margin: 16/75rem 0;
    line-height: 1;
    background: @sui_color_gray_weak2;

    &__content {
      max-height: 100/75rem;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 8/75rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 4/75rem;
      }
    }
  }

  .order-no-item {
    overflow: hidden;
    font-size: 24/75rem;
    color: @sui_color_gray_dark1;
  }

  .order-no-item + .order-no-item {
    margin-top: 12/75rem;
  }
}
</style>

<template>
  <!-- <div> -->
  <s-drawer
    :type="'multi'"
    :visible.sync="show"
    class="refund-reason-drawer"
    :append-to-body="true"
  >
    <!-- append-to-body解决ios内容被组件弹窗遮罩挡住问题 -->
    <template slot="top">
      {{ language.SHEIN_KEY_PWA_15434 }}
    </template>
    <!-- 资产去向文案tip -->
    <div class="refund-drawer-tip">
      <p
        class="refund-drawer-tip__info"
        v-html="htmlDecode(language.SHEIN_KEY_PWA_14597)"
      ></p>
    </div>
    <s-radio-group v-model="value">
      <div
        v-for="(item, index) in reason"
        :key="index"
        :class="`refund-radio`"
      >
        <!-- @click.stop.prevent="fastclick(item.index)" -->
        <!-- 解决ios双击才能点中的问题 -->
        <s-radio
          v-enterkey
          :label="item.index"
          margin-r="0"
          tabindex="0"
          role="button"
          :class="`j-refund-radio${item.index}`"
        >
          <span
            class="refund-radio-txt"
            v-html="htmlDecode(item.val)"
          ></span>
        </s-radio>
        <template v-if="item.index == 75 && value == 75 && addressCanEdit && !isUnPaiMoreAddressOrder">
          <div class="refund-tips">
            <span class="tips">
              {{ language.SHEIN_KEY_PWA_18349 }}
            </span>
            <!-- 新编辑按钮 -->
            <EditAddressBtnNew
              class="refund-edit-btn"
              :button-style="['H48PX']"
              :editAddressNewConfigList="editAddressNewConfigList"
              :order="orderInfo"
              :language="language"
              :newEditAddressOptions="newEditAddressOptions"
              @showEditAddressDialog="editAddress"
            >
              {{ language.SHEIN_KEY_PWA_16969 }}
            </EditAddressBtnNew>
          </div>
        </template>
        <template v-if="item.index == -1 && value == -1">
          <div class="refund-tips">
            <s-radio-group v-model="otherValue">
              <div
                v-for="(subVal, subIndex) in item.sublist"
                :key="subIndex"
                v-enterkey
                :class="`j-refund-radio${subVal.index}`"
                tabindex="0"
                role="button"
                class="refund-tag"
              >
                <!-- @click.stop.prevent="fastclick(subVal.index)" -->
                <!-- 解决ios双击才能点中的问题， refund-tag解决radio-tag样式单一问题 -->
                <s-radio-tag
                  :label="subVal.index"
                  :cancel-mark="false"
                >
                  {{ subVal.val }}
                </s-radio-tag>
              </div>
            </s-radio-group>
          </div>
        </template>
      </div>
    </s-radio-group>

    <!-- 底部 S -->
    <template slot="footer">
      <s-button
        :type="['H80PX', 'primary']"
        :width="9.3"
        :disabled="(value && value != -1) || (value == -1 && otherValue) ? false : true"
        @click="selectRefoundReason"
      >
        {{ language.SHEIN_KEY_PWA_15752 && language.SHEIN_KEY_PWA_15752.toLocaleUpperCase() }}
      </s-button>
    </template>
    <!-- 底部 E -->
    <!-- 地址 -->
    <address-vue
      ref="addressVue"
      :show="addressShow"
      :page-type="editAddressType"
      :extra="extraAddress"
      :disabled="disabledAddress"
      @edit="addressEdit"
      @cancel="refundAddressCancel"
      @error="updateError"
    />
    <!-- 店配地址 -->
    <store-address-vue
      v-if="
        orderInfo.subsidiary && TRANSPORT_TYPE_SHOP.includes(orderInfo.subsidiary.transport_type)
      "
      ref="storeAddress"
      :page-type="'order'"
      @save="saveStoreAddressEvt"
    />
    <!-- 地址编辑成功提示 -->
    <LazyMount>
      <CommonTipsDialog
        :visible.sync="showBatchAddressMessage"
        :append-to-body="true"
        :close-on-click-modal="false"
        :show-close="false"
        :content="batchAddressMessage"
        :btn-type="['primary', 'H56PX']"
        :btn-text="language.SHEIN_KEY_PWA_15312"
        @ok="closeBatchAddressDialog"
      />
    </LazyMount>
  </s-drawer>
  <!-- </div> -->
</template>

<script>
import { LazyMount } from '@shein/sui-mobile'
import schttp from 'public/src/services/schttp'
import addressMixin from 'public/src/pages/user/child_pages/orders/common/addressMixin'
import { abtservice } from 'public/src/services/abt'
import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import Vue from 'vue'
import { mapState } from 'vuex'
import { htmlDecode } from '@shein/common-function'
import CommonTipsDialog from 'public/src/pages/user/child_pages/orders/common/commonTipsDialog'
import EditAddressBtnNew from 'public/src/pages/user/child_pages/orders/detail/components/editAddressBtnNew'
import { Drawer, DrawerItem, RadioGroup, Radio, Button, Dialog, RadioTag } from '@shein/sui-mobile'
;[Drawer, DrawerItem, Radio, RadioGroup, Button, Dialog, RadioTag].forEach(c => Vue.use(c))

export default {
  components: {
    LazyMount,
    EditAddressBtnNew,
    CommonTipsDialog,
  },
  mixins: [addressMixin],
  props: {
    language: {
      type: Object,
      default: () => {
        return {}
      }
    },
    refundForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    reason: {
      type: Array,
      default: () => {
        return []
      }
    },
    billno: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      moduleName: 'refundReason',
      TRANSPORT_TYPE_SHOP,
      show: false,
      value: 0,
      otherValue: 0,
      orderInfo: {},
      cancelAddressStay: true,
      batchModifyAddressAbt: false,
      showEditOrderAddress: false,
      addressCanEdit: false,
      editAddressNewConfigList: [],
      batchModifyAddressParams: {},
    }
  },
  computed: {
    ...mapState('cancelItemModule', ['matchCompName']),
    isCod() {
      return this.orderInfo.payment_method == 'cod'
    },
    isOrderList() {
      return this.$route.name === 'UserOrdersList'
    },
    newEditAddressOptions() {
      return {
        batchModifyAddress: this.batchModifyAddressAbt,
        pageSource: 'refundReason',
        btnSource: 'Edit'
      }
    },
    isUnPaidOrder () {
      return [0, 12, 13]?.includes(+this.orderInfo?.orderStatus)
    },
    isUnPaiMoreAddressOrder(){
      return this.isUnPaidOrder && this.orderInfo?.address_list?.length > 1
    }
  },
  async created() {
    window.refundReasonVue = this
    // eslint-disable-next-line @shein-aidc/abt/abt
    const { CancelStay, Orderdetail, BatchModifyAddress, EditOrderAddress } = await abtservice.getUserAbtResult({
      posKeys: 'CancelStay,Orderdetail',
      newPosKeys: 'BatchModifyAddress,EditOrderAddress'
    })
    this.cancelAddressStay = CancelStay?.param === 'CancelStay=off' ? false : true
    this.isCodAddressEditEnable = Orderdetail?.param?.indexOf('CodAddress=off') > -1 ? false : true
    this.batchModifyAddressAbt = BatchModifyAddress?.param?.order_detail_address == 'on'
    this.showEditOrderAddress = EditOrderAddress?.param?.OrderDetailAddress == 'on'
    this.getOrderDetail()
    if (
      ((this.isCod && this.isCodAddressEditEnable) || (!this.isCod && this.cancelAddressStay)) &&
       this.showEditOrderAddress
    ) {
      this.queryCouldModifyAddress()
    }
  },
  methods: {
    htmlDecode: text => htmlDecode({ text }),
    async queryCouldModifyAddress() {
      const billno = this.$route.params.billno || this.billno
      const res = await schttp({
        url: '/api/orders/base/queryCouldModifyAddress/query',
        method: 'POST',
        data: {
          billno_list: [billno],
          source: this.isOrderList ? 'order_list' : 'order_detail'
        }
      })
      if (res?.code == 0) {
        const orderList = res.info?.order_list || []
        this.editAddressNewConfigList = orderList
        const currentOrder = orderList.find(item => item.billno == billno)
        this.addressCanEdit = currentOrder?.is_order_address_editable == 1
      }
    },
    async getOrderDetail() {
      let { billno } = this.$route.params
      billno = billno || this.billno
      const json = await schttp({
        url: `/api/orders/base/get_order_info/get`,
        method: 'GET',
        params: {
          isRefund: true,
          billno
        },
      })
      if (json?.code == 0 && json?.info?.order) {
        this.orderInfo = json.info.order
      }
    },
    selectRefoundReason() {
      let boolean = true
      let checkData
      if (this.value == -1) {
        boolean = false
      }
      this.reason.filter(item => {
        if (!boolean && item?.sublist?.length) {
          return item.sublist.filter(i => {
            if (i.index == this.otherValue) {
              return (checkData = i)
            }
          })[0]
        } else {
          if (item.index == this.value) {
            return (checkData = item)
          }
        }
      })
      this.show = false
      this.$emit('selectRefoundReason', boolean, checkData)
    },
    editAddress(argParams = {}) {
      this.click_cancel_page_edit_address()
      const { options } = argParams || {}
      this.batchModifyAddressParams = options.extraParams || {}
      this.showEditAddressDialogEvt(argParams)
    },
    click_cancel_page_edit_address() {
      let refundGoods = []
      const billno = this.orderInfo.billno
      const payment_method = this.orderInfo.payment_method == 'cod' ? 1 : 2

      if (this.refundForm?.data?.refundGoods?.length) {
        refundGoods = this.refundForm.data.refundGoods.map(goods => {
          return goods.goods_id
        })
      } else {
        const goods = this.orderInfo?.oldOrderGoodsList || []
        refundGoods = goods.map(goods => {
          return goods.goods_id
        })
      }
      sa('send', {
        activity_name: 'click_cancel_page_edit_address',
        activity_param: {
          order_id: billno,
          goods_id: refundGoods.join(),
          order_type: payment_method
        }
      })
    },
    toggleUnavailableModal() {
      this.$toast(this.language.SHEIN_KEY_PWA_17115)
    },
    refundAddressCancel() {
      setTimeout(() => {
        this.show = true
      }, 200)
      this.addressCancel()
    },
    fastclick(inx) {
      $(`.j-refund-radio${inx} input`).click().click()
    }
  }
}
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.refund-reason-drawer {
  .refund-drawer-tip {
    padding: 0.32rem;
    box-sizing: border-box;
    background-color: #f6f6f6;
    &__info {
      color: #222;
      font-size: 14px;
      line-height: 1.35;
    }
  }
}
.refund-radio {
  display: block;
  border-bottom: 1px solid #e5e5e5;
  .margin-l(0.32rem);
  padding: 0.26rem;
  .padding-l(0);
  .refund-radio-txt {
    white-space: initial;
  }
  .refund-tips {
    background: #f6f6f6;
    .font-dpr(28px);
    padding: 0.32rem 0.7rem;
    margin: 0.32rem -0.32rem -0.32rem;
    .padding-r(0.32rem);
    .border-l(0.32rem, #f6f6f6);
    position: relative;
    &::before {
      display: block;
      content: '';
      width: 0.3rem;
      height: 0.3rem;
      position: absolute;
      background: #f6f6f6;
      .left(1.63rem);
      top: -0.15rem;
      transform: rotate(45deg);
    }
    .tips {
      display: inline-block;
      padding: 0.213rem 0;
    }
    .refund-tag {
      margin-bottom: 0.213rem;
      &:last-child {
        margin-bottom: 0;
      }
      .S-radio-tag {
        & when(@IS_SH) {
          border: 1px solid #222;
        }
        opacity: 0.7;
      }
      .S-radio-tag__wrap {
        background: #fff;
        & when(@IS_SH) {
          color: #222;
        }
      }
      .S-radio__input-custom_checked .S-radio-tag {
        opacity: 1;
        .S-radio-tag__wrap:after {
          & when(@IS_SH) {
            border: none;
          }
        }
      }
    }
  }
}
</style>

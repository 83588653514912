/**
 * login模块按需加载模块
 * 该文件在 public/common/index.js 里面引入
 */
import Vue from 'vue'

const evt = {
  isLoadingJsModule: false,
  vueInstance: null
}

const isHasLoadModule = false

evt.showPop = (cb) => {
  if (!isHasLoadModule && !evt.vueInstance) {
    initLoginPageLoadingModal(cb)
  } else {
    cb()
  }
}

evt.toEditItem = (data, suc) => {
  window.__shippingCountryId = data.shippingCountryId
  window.__payMethod = data.payMethod
  evt.showPop(() => {
    const instance = evt.vueInstance
    instance.$store.commit('updateStateArea', { key: 'receiverCountry', val: data?.receiverCountry || '' })
    instance.$store.commit('updateItemData', { mode: 'edit', data })
    instance.$store.commit('replaceActionPath', 'item')
    instance.showDrawer(suc)
  })
}

evt.toAddItem = (data, suc) => {
  window.__shippingCountryId = data.shippingCountryId
  window.__payMethod = data.payMethod
  evt.showPop(() => {
    const instance = evt.vueInstance
    instance.$store.commit('updateStateArea', { key: 'receiverCountry', val: data?.receiverCountry || '' })
    instance.$store.commit('replaceActionPath', 'index')
    instance.showDrawer(suc)
  })
}

// 创建登录模块的加载 HTML
function createLoginHtml () {
  const loadingHtml = document.createElement('div') // loading HTML
  loadingHtml.setAttribute('class', 'j-return-payments-loading')
  loadingHtml.innerHTML = '<s-loading type="curpage" :mask-transparent="true" :show="isShowLoading"></s-loading>'

  const loginHtml = document.createElement('div') // 登录弹窗HTML
  loginHtml.setAttribute('class', 'j-return-payments')

  return { loginHtml, loadingHtml }
}

// 初始化登录模块
function initLoginPageLoadingModal (cb) {
  if (evt.isLoadingJsModule) return

  evt.isLoadingJsModule = true

  const el = createLoginHtml()

  const fragment = document.createDocumentFragment()

  fragment.appendChild(el.loadingHtml)
  fragment.appendChild(el.loginHtml)

  document.body.appendChild(fragment)
  // eslint-disable-next-line no-new
  new Vue({
    el: el.loadingHtml,
    data: { isShowLoading: false },
    mounted () {
      this.isShowLoading = true
      import(/* webpackChunkName: "async-returnpayments" */ './index').then((res) => {
        const vueInstance = res && res.default && res.default.$children && res.default.$children[0]
        evt.vueInstance = vueInstance
        setTimeout(() => {
          if (vueInstance.isMounted) {
            this.isShowLoading = false
            cb()
          } else {
            vueInstance.$on('event-module-mounted', () => {
              this.isShowLoading = false
              cb()
            })
          }
        }, 0)
      })
    }
  })
}

export default evt

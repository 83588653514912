<template>
  <div class="page__cancelOrder_refund-account">
    <div
      v-show="accountInfo.info.paymentType == 'PayPal' && accountInfo.selected"
      class="page__cancelOrder_refund-desc"
      tabindex="0"
      v-html="langText.SHEIN_KEY_PWA_19466"
    ></div>
    <div
      v-show="accountInfo.info.paymentType == 'bankaccount' && accountInfo.selected"
      class="page__cancelOrder_refund-desc"
      tabindex="0"
      v-html="langText.SHEIN_KEY_PWA_19467"
    ></div>
    <div class="page__cancelOrder_refund-info">
      <span 
        class="refund-info-title"
        :class="{'is-required': required}"
      > 
        {{ langText.SHEIN_KEY_PWA_17299 }}
      </span>
      <span>
        <em
          v-if="accountInfo.selected"
          class="is-selected"
        >
          {{ accountInfo.selected }}
        </em>
        <em
          v-else
          @click="showRefundAccountDrawerFn"
          v-html="langText.SHEIN_KEY_PWA_17301"
        ></em>
        <i
          v-if="accountCanEdit"
          class="suiiconfont sui_icon_nav_back_24px_mir"
          @click="showRefundAccountDrawerFn"
        ></i>
      </span>
    </div>
    <SAlert
      v-if="accountNeedMaintain"
      jumpable
      type="warning"
      class="page__cancelOrder_refund-alert"
      @click.native="showRefundAccountDrawerFn()"
    >
      <div
        tabindex="0"
        class="maintain-text"
        v-html="langText.SHEIN_KEY_PWA_33064"
      >
      </div>
    </SAlert>
    <div 
      v-if="showRequiredTips"
      class="account-required-text"
      v-html="langText.SHEIN_KEY_PWA_31943"
    >
    </div>
    <div
      v-show="accountInfo.selected == '' && accountInfo.account && showApply"
      class="page__cancelOrder_refund-tips"
    >
      <span v-html="tsAccountTips"></span>
      <a
        href="javascript:void(0);"
        @click="applyRefundAccount"
      >
        {{ langText.SHEIN_KEY_PWA_15238 }}
      </a>
    </div>
    <div
      class="page__cancelOrder_refund-desc"
      tabindex="0"
      v-html="refundAccountTips"
    ></div>
  </div>
</template>

<script>
import orderLogic from 'public/src/pages/common/orderLogic'
import ReturnPaymentAccount from 'public/src/pages/returnPayments/asyncIndex.js'
import JumpCountry from 'public/src/pages/common/orderLogic/jumpCountry.js'

if (typeof window !== 'undefined') {
  window.ReturnPaymentAccount = ReturnPaymentAccount
}

export default {
  props: {
    langText: { type: Object, default: () => ({}) },
    receiverCountry: { type: String, default: '' },
    shippingCountryId: { type: String, default: '' },
    accountInfo: { type: Object, default: () => ({}) },
    show: { type: Boolean, default: false },
    order: { type: Object, default: () => ({}) },
    required: { 
      type: Boolean, 
      default: false 
    },
    showRequiredTips: { 
      type: Boolean, 
      default: false 
    },
  },
  data() {
    return {
      countryList: []
    }
  },
  computed: {
    tsAccountTips() {
      // return ''
      const info = (this.accountInfo && this.accountInfo.info) || {}
      const { accountDisplay, paymentType } = info
      return (this.langText.SHEIN_KEY_PWA_17355 || '')
        .replace('{0}', paymentType)
        .replace('{1}', `<b>${accountDisplay}</b>`)
    },
    showApply() {
      const { countryCode, paymentType } = this.accountInfo.info
      const { shipping_country_name, payment_method } = this.order
      return (
        !(shipping_country_name == 'Brazil') ||
        (payment_method == 'ebanx-boleto' && paymentType == 'bankaccount' && countryCode == 'BR')
      )
    },
    refundAccountTips() {
      return orderLogic.getRefundAccountTips({
        receiverCountry: this.receiverCountry,
        paymentType: this.accountInfo.info?.paymentType,
        language: this.langText,
        hasAccount: !!this.accountInfo.account
      })
    },
    accountCanEdit() {
      return this.accountInfo.info?.isUpdatable != 0
    },
    accountNeedMaintain() {
      return orderLogic.isNeedMaintainName({
        itemData: this.accountInfo.info,
        receiverCountry: this.receiverCountry,
        optionalPaymentMethod: this.accountInfo?.optionalPaymentMethod || []
      })
    },
  },
  watch: {
    show(v) {
      if (v) {
        const name = this.accountInfo.selected
          ? 'expose_editrefundaccount'
          : 'expose_addrefundaccount'
        window.sa?.('send', { activity_name: name })
      }
    }
  },
  mounted() {
    this.getCountryList()
  },
  methods: {
    async getCountryList() {
      const list = await JumpCountry.getLocationList()
      this.countryList = list
    },
    applyRefundAccount() {
      this.updateRefundAccountInfo({ selected: this.accountInfo.account })
    },
    updateRefundAccountInfo(data) {
      this.$emit('update-account-info', data)
    },
    showRefundAccountDrawerFn() {
      const payMethod = this.order.payment_method
      const receiverCountry = this.receiverCountry
      const shippingCountryId = this.shippingCountryId
      let receiverCountryCode = ''
      const name = this.accountInfo.selected ? 'click_editrefundaccount' : 'click_addrefundaccount'
      window.sa?.('send', { activity_name: name })
      if (receiverCountry) {
        const v = this.countryList.find(v => v.country == receiverCountry)
        receiverCountryCode = v?.value || ''
      }
      if (this.accountInfo.selected) {
        ReturnPaymentAccount.toEditItem(
          {
            ...this.accountInfo.info,
            receiverCountry: receiverCountryCode,
            shippingCountryId,
            payMethod
          },
          data => {
            if (data == 'del') {
              this.updateRefundAccountInfo({ account: '', selected: '', info: {} })
              return
            }
            this.updateRefundAccountInfo({
              account: data.accountDisplay,
              selected: data.accountDisplay,
              info: data
            })
          }
        )
        return
      }
      ReturnPaymentAccount.toAddItem(
        { receiverCountry: receiverCountryCode, shippingCountryId, payMethod },
        data => {
          if (data == 'del') {
            this.updateRefundAccountInfo({ account: '', selected: '', info: {} })
            return
          }
          this.updateRefundAccountInfo({
            account: data.accountDisplay,
            selected: data.accountDisplay,
            info: data
          })
        }
      )
    }
  }
}
</script>

<style lang="less">
.page {
  &__cancelOrder {
    &_refund-account {
      padding-left: 0.8rem;

      .account-required-text {
        .font-dpr(24px);
        color: @sui_color_unusual;
      }
    }
    &_refund-info {
      padding: 30/75rem 0;
      display: flex;
      justify-content: space-between;
      span:last-of-type {
        color: #9b9b9b;
      }
      em.is-selected {
        color: @sui_color_gray_dark1;
      }
      .refund-info-title {
        &.is-required:before {
          content: '*';
          color: @sui_color_unusual;
        } 
      }
    }
    &_refund-tips {
      background: #f6f6f6;
      padding: 16/75rem;
      margin-bottom: 16/75rem;
      .font-dpr(24px);
      a {
        color: #1860a7;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
      }
    }
    &_refund-desc {
      color: #767676;
      .font-dpr(24px);
    }
    &_refund-alert {
      margin-bottom: 12/75rem;
    }
  }
}
</style>

<template>
  <div class="coupon-artical-page">
    <div class="artical-page_header">
      <div class="header-title">
        {{ language.SHEIN_KEY_PWA_15430 }}
        <Icon
          v-if="showRdfTips"
          name="sui_icon_doubt_16px_2" 
          size="14px"
          class="header-icon"
          :custom-style="{ lineHeight: 1 }"
          @click="showRefundCalMaskTipsEvt"
        />
      </div>
      <div
        class="header-amount"
        :class="{ 'header-amount-hide': !(showCouponArticalPage && existSomeAmountValue) }"
      >
        {{ refundTotalAmount }}
      </div>
    </div>
    <div
      v-if="showCouponArticalPage && existSomeAmountValue"
      v-expose="{ id: scene == 'allRefund' ? '1-19-2-5' : '1-19-1-146', data: getDetailDataInfo }"
      class="artical-page_body"
    >
      <div
        v-if="discountedAmount.amount > 0"
        class="body-item body-amount-item"
      >
        <span class="item-text">{{ language.SHEIN_KEY_PWA_24767 }}:</span>
        <span class="item-amount">{{ discountedAmount.amountWithSymbol }}</span>
      </div>
      <div class="body-item body-detail-item">
        <span class="item-text item-gray">{{ language.SHEIN_KEY_PWA_24761 }}</span>
        <span class="item-amount item-gray">
          <Icon
            v-show="toggleDetailDown"
            name="sui_icon_more_up_12px_2" 
            size="14px"
            :custom-style="{ lineHeight: 1 }"
            @click="toggleDetailEvt"
          />
          <Icon
            v-show="!toggleDetailDown"
            name="sui_icon_more_down_12px_2" 
            size="14px"
            :custom-style="{ lineHeight: 1 }"
            @click="toggleDetailEvt"
          />
        </span>
      </div>
      <template v-if="toggleDetailDown">
        <div
          v-if="refundBillGrillAmount.amount > 0"
          v-expose="{ id: scene == 'allRefund' ? '1-19-2-1' : '1-19-1-82', data: { order_no: order.billno } }"
          class="body-item body-rl-item"
        >
          <span class="item-text">
            {{ language.SHEIN_KEY_PWA_24769 }}:
            <Icon
              name="sui_icon_doubt_16px_2" 
              size="14px"
              :custom-style="{ lineHeight: 1 }"
              @click="showFeeTipsEvt(1)"
            />
          </span>
          <span class="item-amount item-reduce">-{{ refundBillGrillAmount.amountWithSymbol }}</span>
        </div>
        <div
          v-if="realRecFreightAmount.amount > 0"
          class="body-item"
        >
          <span class="item-text">
            {{ language.SHEIN_KEY_PWA_33024 }}:
            <Icon
              name="sui_icon_doubt_16px_2" 
              size="14px"
              :custom-style="{ lineHeight: 1 }"
              @click="showFeeTipsEvt(2)"
            />
          </span>
          <span class="item-amount item-reduce">-{{ realRecFreightAmount.amountWithSymbol }}</span>
        </div>
        <div
          v-if="realVipGiveWalletAmount.amount > 0"
          class="body-item"
        >
          <span class="item-text">
            {{ language.SHEIN_KEY_PWA_34219 }}:
            <Icon
              name="sui_icon_doubt_16px_2" 
              size="14px"
              :custom-style="{ lineHeight: 1 }"
              @click="showFeeTipsEvt(3)"
            />
          </span>
          <span class="item-amount item-reduce">-{{ realVipGiveWalletAmount.amountWithSymbol }}</span>
        </div>
      </template>
    </div>
    <div
      class="artical-page_tips"
    >
      <div
        v-if="showGameRewardTips || order.shipping_country_id == 30 || walletShouldFreezeAmount.amount > 0"
        class="body-item"
      >
        <div
          v-if="showGameRewardTips"
          v-expose="{ id: scene == 'allRefund' ? '1-19-2-4' : '1-19-1-145', data: { wallet_expire_type: gameRewardType } }"
          class="item-info"
        >
          <Icon
            name="sui_icon_caution_12px_2"
            size="12px"
            class="item-tip"
            :custom-style="{ lineHeight: 1 }"
          />
          <span v-html="gameRewardText"></span>
        </div>
        <div
          v-if="order.shipping_country_id == 30"
          class="item-info"
        >
          <Icon
            name="sui_icon_caution_12px_2"
            size="12px"
            class="item-tip"
            :custom-style="{ lineHeight: 1 }"
          />
          <span v-html="language.SHEIN_KEY_PWA_25496"></span>
        </div>
        <div
          v-if="walletShouldFreezeAmount.amount > 0"
          class="item-info"
        >
          <Icon
            name="sui_icon_caution_12px_2"
            size="12px"
            class="item-tip"
            :custom-style="{ lineHeight: 1 }"
          />
          <span v-html="language.SHEIN_KEY_PWA_34221"></span>
        </div>
      </div>
      <div class="body-item">
        <div
          class="item-link"
          v-html="getServiceTips"
        >
        </div>
      </div>
    </div>

    <!-- 费用详细提示 -->
    <CommonTipsDialog
      v-if="feeDetailDialogText"
      :visible.sync="feeDetailDialog"
      :append-to-body="true"
      :content="feeDetailDialogText"
      :btn-type="['primary', 'H56PX']"
      :btn-text="language.SHEIN_KEY_PWA_15146"
    />

    <!-- RDF不退说明弹窗 -->
    <RdfTaxesTipsDialog
      v-if="showRdfTips"
      :visible.sync="showRdfTaxesDialog"
      :language="language"
      :rdf-order-no-list="refundOrder.relation_not_deliver_billnos"
      :rdf-refund="refundOrder.relation_rdf_to_be_refund"
    />
  </div>
</template>
<script>
import { expose } from 'public/src/pages/common/analysis/directive.js'
export default {
  directives: { expose }
}
</script>
<script setup>
import { computed, ref } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { template } from '@shein/common-function'
import CommonTipsDialog from 'public/src/pages/user/child_pages/orders/common/commonTipsDialog'
import RdfTaxesTipsDialog from 'public/src/pages/user/child_pages/orders/common/cancelItem/comps/RdfTaxesTipsDialog.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
const { langPath = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

daEventCenter.addSubscriber({ modulecode: '1-19-1' })
daEventCenter.addSubscriber({ modulecode: '1-19-2' })
let props = defineProps({
  scene: {
    type: String,
    default: ''
  },
  isDemotion: {
    type: Boolean,
    default: false
  },
  gameRewardType: {
    type: Number,
    default: 0
  },
  abtInfo: {
    type: Object,
    default: () => {}
  },
  language: {
    type: Object,
    default: () => {}
  },
  order: {
    type: Object,
    default: () => {}
  },
  refundOrder: {
    type: Object,
    default: () => {}
  }
})

let order = computed(() => props?.order)
let refundOrder = computed(() => props?.refundOrder)
let language = computed(() => props?.language)
let scene = computed(() => props?.scene)
let showRdfTaxesDialog = ref(false)
let toggleDetailDown = ref(true)
let feeDetailDialogText = ref('')
let feeDetailDialog = ref(false)

// 退款总金额
let refundTotalAmount = computed(() => {
  let total_refund = props?.refundOrder?.omsData?.total_refund || props?.refundOrder?.omsData?.price?.total_refund_price || {}
  let isDemotion = props?.isDemotion

  return isDemotion ? '- -' : total_refund?.amountWithSymbol || ''
})

// 满足重计算标识提示
let showCouponArticalPage = computed(() => {
  let couponArticalPage = props?.abtInfo?.couponArticalPage

  let realRecFreight = props?.refundOrder?.omsData?.real_rec_freight || props?.refundOrder?.omsData?.price?.real_rec_freight || {}
  let refundBillGrill = props?.refundOrder?.refund_goods_amount?.refund_bill_grill || {}
  let realVipGiveWallet = props?.refundOrder.omsData?.real_vip_give_wallet || props?.refundOrder?.omsData?.price?.real_vip_give_wallet || {}

  return couponArticalPage && (realRecFreight?.amount > 0 || refundBillGrill?.amount > 0 || realVipGiveWallet?.amount > 0)
})

// 满足展示RDF不退的场景
let showRdfTips = computed(() => {
  const relation_not_deliver_billnos = props?.refundOrder?.relation_not_deliver_billnos
  const relation_rdf_to_be_refund = props?.refundOrder?.relation_rdf_to_be_refund
  if (Array.isArray(relation_not_deliver_billnos) && relation_rdf_to_be_refund) {
    return relation_not_deliver_billnos.length > 0 && Number(relation_rdf_to_be_refund.amount) > 0
  }
  return false
})

// 商品总折后价
let discountedAmount = computed(() => {
  return props?.refundOrder?.refund_goods_amount?.discounted || {}
})

// 促销扒回金额
let refundBillGrillAmount = computed(() => {
  return props?.refundOrder?.refund_goods_amount?.refund_bill_grill || {}
})
// 运费扒回金额
let realRecFreightAmount = computed(() => {
  return props?.refundOrder.omsData?.real_rec_freight || props?.refundOrder?.omsData?.price?.real_rec_freight || {}
})
// 付费会员权益扒回金额
let realVipGiveWalletAmount = computed(() => {
  return props?.refundOrder.omsData?.real_vip_give_wallet || props?.refundOrder?.omsData?.price?.real_vip_give_wallet || {}
})
// 付费会员扒回提示
let walletShouldFreezeAmount = computed(() => {
  return props?.refundOrder.omsData?.wallet_should_freeze || props?.refundOrder?.omsData?.price?.wallet_should_freeze || {}
})
// 金额是否存在
let existSomeAmountValue = computed(() => {
  return discountedAmount?.value?.amount > 0 || refundBillGrillAmount?.value?.amount > 0 || realRecFreightAmount?.value?.amount > 0 || realVipGiveWalletAmount?.value?.amount > 0
})

// 满足钱包活动提示
let showGameRewardTips = computed(() => {
  let wallet = props?.refundOrder?.refund?.wallet || props?.refundOrder?.omsData?.price?.wallet_refundable?.usd || 0
  let gameRewardType = props.gameRewardType
  
  return wallet > 0 && gameRewardType > 1
})

// 游戏余额提示文案
let gameRewardText = computed(() => {
  let gameRewardType = props.gameRewardType
  let textMap = {
    '2': language.value?.SHEIN_KEY_PWA_32194 || '',
    '3': language.value?.SHEIN_KEY_PWA_32195 || '',
    '4': language.value?.SHEIN_KEY_PWA_32196 || ''
  }
  return textMap[gameRewardType] || ''
})

// service文案
let getServiceTips = computed(() => {
  if (realRecFreightAmount?.value?.amount > 0) {
    return template(`${langPath}/Shipping-Info-a-280.html`, language.value?.SHEIN_KEY_PWA_33023, `${langPath}/Return-Policy-a-281.html`, language.value?.SHEIN_KEY_PWA_33191, language.value?.SHEIN_KEY_PWA_33190)
  }
  return template(`${langPath}/Return-Policy-a-281.html`, language.value?.SHEIN_KEY_PWA_33191, language.value?.SHEIN_KEY_PWA_33026)
})

// 埋点参数
let getDetailDataInfo = () => {
  let detailTypeList = []
  if (realRecFreightAmount?.value?.amount > 0) {
    detailTypeList.push(1)
  }
  if (refundBillGrillAmount?.value?.amount > 0) {
    detailTypeList.push(2)
  }
  if (realVipGiveWalletAmount?.value?.amount > 0) {
    detailTypeList.push(3)
  }
  return {
    detail_type: detailTypeList.join(',')
  }
}

let showRefundCalMaskTipsEvt = () => {
  showRdfTaxesDialog.value = true
}
let toggleDetailEvt = () => {
  toggleDetailDown.value = !toggleDetailDown.value
}
let showFeeTipsEvt = type => {
  if (type == 1) {
    daEventCenter.triggerNotice({
      daId: scene.value == 'allRefund' ? '1-19-2-2' : '1-19-1-83',
      extraData: {
        order_no: props?.order?.billno || ''
      }
    })
  }
  let textMap = {
    1: language.value?.SHEIN_KEY_PWA_21703,
    2: language.value?.SHEIN_KEY_PWA_33025,
    3: language.value?.SHEIN_KEY_PWA_34220
  }
  feeDetailDialogText.value = textMap[type] || ''
  feeDetailDialog.value = true
}
</script>
<style lang="less" scoped>
.coupon-artical-page{
  .artical-page_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-title{
      display: flex;
      align-items: center;
    }
    .header-amount{
      position: relative;
      &::after{
        content: '';
        display: block;
        position: absolute;
        bottom: -16/75rem;
        right: 50%;
        margin-right: -8/75rem;
        border: 16/75rem solid transparent;
        border-bottom-color: #F6F6F6;
        width: 16/75rem;
        height: 16/75rem;
        display: inline-block;
      }
      &.header-amount-hide{
        &::after{
          display: none;
        }
      }
    }
    .header-icon{
      margin-left: 4px;
    }
  }
  .artical-page_body{
    margin-top: 16/75rem;
    padding: 24/75rem;
    background: #F6F6F6;
    .body-item{
      display: flex;
      justify-content: space-between;
      &:last-child{
        margin-bottom: 0;
      }
      &.body-amount-item{
        margin-bottom: 24/75rem;
      }
      &.body-detail-item{
        margin-bottom: 12/75rem;
      }
      &.body-rl-item{
        margin-bottom: 16/75rem;
      }
    }
    .item-text{
      color: #222;
      font-size: 28/75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      .item-warn{
        display: flex;
        align-items: center;
      }
    }
    .item-amount{
      margin-left: 20/75rem;
      color: #222;
      font-size: 28/75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &.item-reduce{
        color: #C44A01;
      }
    }
    .item-gray{
      color: #767676;
    }
    .item-icon{
      margin-left: 10/75rem;
    }
  }
  .artical-page_tips{
    margin-top: 16/75rem;
    padding-top: 16/75rem;
    border-top: 1px solid #e5e5e5;
    .item-info{
      margin-bottom: 16/75rem;
      color: #A86104;
      font-size: 24/75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      &:last-child{
        margin-bottom: 0;
      }
    }
    .item-tip{
      margin-right: 8/75rem;
    }
    .item-link{
      margin-top: 16/75rem;
      color: #222;
      font-size: 24/75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
      a{
        color: #2D68A8;
        text-decoration: none;
      }
    }
  }
}
</style>
